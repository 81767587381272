.custom_container {
  @apply flex pt-10 pb-[5rem] justify-center items-center flex-col px-4 bg-white h-screen;
}

.card {
  max-width: 45.938rem;
  box-shadow: 4px 4px 20px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 4.25rem 2.5rem;
  @apply bg-white relative z-0 w-full;
  overflow: inherit;
}
.custom_card .card {
  max-width: 31.25rem;
  @apply w-full;
}

@screen maxMd {
  .card {
    @apply py-10 px-8;
  }
}

.card::before {
  content: "";
  background: url("../images/loginbg.png") no-repeat center / contain;
  width: 15.5rem;
  height: 15rem;
  @apply -bottom-px -left-px absolute -z-10;
}

@screen maxMd {
  .card::before {
    width: 7rem;
    height: 7rem;
    @apply w-24 h-24 left-[-0.05rem] bottom-[-0.15rem];
  }
}

.cardtext {
  max-width: 16.875rem;
  @apply mx-auto;
}

@screen maxMd {
  .cardtext {
    @apply max-w-full mb-5;
  }
}

.inputRight {
  max-width: 17.75rem;
  @apply ml-auto;
}

@screen maxMd {
  .inputRight {
    @apply max-w-full ml-0;
  }
}

.form_control {
  @apply w-full !bg-anflshwht outline-none focus:border-darkturquoise border focus:bg-white;
  border-radius: 8px;
  height: 40px;
  padding: 0rem 2.5rem 0rem 0.875rem;
  background-color: rgb(244 245 246 / var(--tw-bg-opacity));
}

.form_control:focus-visible {
  outline: none;
}

@screen maxMd {
  .form_control {
    padding: 0.625rem 2.5rem 0.625rem 0.625rem;
  }
}
.form_label {
  @apply font-medium text-sm mb-0.5 block text-davygrey capitalize;
}

.close_icon,
.view_icon {
  @apply relative;
}
.view_icon button {
  right: 0.875rem;
  @apply absolute w-6 h-10 my-auto;
}
.view_icon button img {
  @apply object-contain w-5 h-5;
}
@screen maxMd {
  .view_icon button {
    @apply top-[0.563rem] h-auto;

  }
}

.close_icon::before {
  content: "";
  background: url("../images/close.svg") no-repeat center / contain;
  right: 0.875rem;
  width: 1.188rem;
  height: 1.188rem;
  top: 2.375rem;
  @apply absolute;
}

@screen maxMd {
  .close_icon::before {
    @apply top-3;
  }
}

@screen maxMd {
  .view_icon::before {
    @apply top-[2.125rem];
  }
}
.custom_container .inputRight {
  max-width: 20rem;
  @apply mx-auto;
}
.custom_container.custom_card {
  margin-top: 0px;
}
.custom_card .cardtext {
  max-width: 20rem;
}
.custom_card .card::before {
  @apply bg-none opacity-0 invisible;
}
.property_alerts {
  @apply flex justify-start flex-col items-center px-4 pt-10 pb-[11rem] bg-white;
}
.property_alerts .card {
  @apply px-[22px] py-[26px];
}

.property_alerts .card::before {
  background: none;
}
.down_arrow {
  @apply relative;
}
.down_arrow::before {
  content: "";
  background: url("../images/arrow_down.svg") no-repeat center / contain;
  @apply w-6 h-6 absolute pointer-events-none right-2.5 top-3.5;
}
