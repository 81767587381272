.parent {
  @apply bg-white h-full text-white w-[89px] maxXl:w-[89px];
  position: unset;
}

.container {
  @apply relative maxMd:pt-12 maxMd:pb-28 pb-6 pt-[6.3625rem] h-full w-auto;
}

.container>button {
  @apply absolute top-2 right-4;
}

.tooltip {
  position: relative;
}

.tooltip .tooltiptext {
  padding-right: 0.75rem;
  padding-left: 0.45rem;
  border-radius: 3.3px 6px 6px 3.5px;
  color: #30345e;
  font-family: "Inter-Bold";
  border: 0.5px solid #30345e;
  @apply bg-white absolute hidden pt-[8px] pb-[8px] text-center z-[2] h-[24.8px] text-[11px] font-bold top-0 left-[55px] whitespace-nowrap;
}

@screen maxMd {
  .tooltip .tooltiptext {
    @apply pt-0 text-aurometalsaurus;
  }

  .tooltip .tooltiptext {
    border: none;
  }
}

.tooltip .tooltiptext::before {
  @apply bg-white overflow-hidden h-[18px] w-[18px] left-[-8px] absolute top-[3px] maxMd:hidden;
  content: "";
  transform: rotate(45deg);
  border-radius: 5px 43px 5px 6px;
  border-bottom: 0.9px solid;
  border-color: #30345e;
  border-left: 0.9px solid;
}

.nav a:hover .tooltiptext {
  display: flex;
  align-items: center;
}

.nav {
  @apply maxMd:pt-6 flex flex-col items-center;
}

.nav>* {
  @apply flex justify-center cursor-pointer;
}

.nav>*:not(:first-child) {
  @apply mt-[1.875rem];
}

.nav hr {
  display: none;
}

.user {
  @apply flex items-center;
  padding: 1.8rem 0 0 3.875rem !important;
}

.user svg {
  margin-right: 13px !important;
}

/* .nav img {
  @apply w-[22px] h-[22px] object-contain mr-0;
} */
.nav svg {
  width: 26px;
  height: 26px;
}

.logout {
  @apply flex items-center pl-[38px] mt-3 mb-4;
}

.badges {
  @apply bg-darkturquoise text-xs grid place-items-center rounded-full absolute w-[1.4rem] h-[1.4rem] text-[8px] top-[-15px] right-[-15px];
  padding: 3px 4px;
}

.user img {
  @apply w-[26px] h-[26px] object-cover mr-2.5 mt-1;
}

.nav svg,
.user svg {
  @apply text-frenchpass;
  transition: 0.3s all;
}

.user svg {
  margin-right: 20px;
}

.nav a {
  @apply relative;
}

.nav a:hover::before,
.nav a.active::before {
  @apply absolute w-[50px] h-[50px] top-[-12px] rounded-[5px];
  content: "";
  background: #f3f4f5;
}

@screen maxMd {
  .container {
    overflow-y: auto;
  }

  .nav a:hover::before,
  .nav a.active::before {
    @apply w-10 h-10 top-[-9px] right-[-8px];
  }

  .nav a:last-child:hover::before,
  .nav a:last-child.active::before {
    @apply hidden;
  }
}

.user svg {
  height: 25px !important;
}

.tooltip__profile img {
  @apply h-10 w-10;
}

@screen maxMd {
  .parent {
    width: 300px;
    z-index: 200;
  }

  .tooltip .tooltiptext {
    display: block !important;
    background-color: inherit;
    left: 30px;
    border: none;
    @apply text-base;
  }

  .tooltip .tooltiptext::before {
    position: initial;
  }

  .nav {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 35px;
    padding-bottom: 30px;
  }

  .nav hr {
    display: block;
    width: 85%;
  }
}

.user svg {
  height: 20px;
  color: #fff !important;
}

.logout {
  display: none;
}

@screen xl {
  .user {
    @apply hidden;
  }
}

@media (min-width: 767px) and (max-width: 1279px) {
  .user {
    padding: 1.8rem 0 0 2.575rem !important;
    display: none;
  }
}

@screen maxMd {
  .nav svg {
    height: 23px;
    width: 23px;
  }

  .logout {
    @apply text-[16px] pl-0;
    font-family: "Inter-Bold";
  }

  .user svg {
    height: 25px;
    fill: #fff;
    margin-right: 21px !important;
  }

  .logout {
    display: block;
  }

  .user {
    @apply !m-0;
    padding: 0.8rem 0 0 0.6rem !important;
  }

  .user span {
    font-family: "Inter-Bold";
    font-size: 1rem;
    line-height: 1.5rem;
    @apply text-aurometalsaurus
  }
}

.user svg path {
  @apply fill-aurometalsaurus;
}