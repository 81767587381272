.authContent {
  @apply px-9 pb-9 maxLg:bg-white maxLg:z-[1] maxLg:rounded-xl max-w-none overflow-y-auto max-h-screen maxLg:max-h-[calc(100vh-3rem)] h-full relative;
}

.formLayout {
  @apply mt-10 pr-1;
}

.formLabel {
  @apply text-nickel font-medium mt-2 text-base maxMd:text-sm;
}

.formInput {
  @apply border hover:border-darkturquoise focus:border-darkturquoise rounded-lg w-full outline-0 p-4 mt-2 hover:shadow-agentsAuthInputs focus:shadow-agentsAuthInputs transition;
}

.formButton {
  @apply !bg-metallicBlue text-white w-full p-4 rounded-md mt-8 hover:opacity-[0.9] transition;
}
.formButton:disabled{
  @apply pointer-events-none opacity-[0.6];

}
