.card {
  @apply border border-silversand bg-white rounded-md;
}

.description {
  @apply text-black text-[0.8125rem];
}

.homeCard {
  @apply card p-4 justify-between flex flex-col;
}

.homeCard h3 {
  @apply text-lg mt-[0.8125rem] text-yankeesblue font-bold leading-[1.575rem];
  font-family: "Manrope";
}

.homeCard h5 {
  @apply text-base mb-3 text-yankeesblue font-bold leading-[1.4rem];
  font-family: "Manrope";
}

.homeCard p {
  @apply text-xs mb-2 text-yankeesblue font-medium leading-[1.05rem];
}

.homeValueCard {
  @apply py-3 px-5 card;
}

.homeValueCard select {
  @apply ml-auto rounded-md outline-none border bg-transparent text-moonStone text-xs border-moonStone;
}

.headingMd {
  font-family: "Inter-SemiBold";
  @apply text-black text-base font-semibold;
}

.equityMyHomeCard {
  @apply card py-4 p-5;
}

.monthlyPayment {
  @apply border border-silversand rounded-[0.1875rem] flex;
}

.monthlyPayment span {
  @apply bg-anflshwht p-2 border-l border-silversand;
}

.selectMenu {
  @apply appearance-none !pr-[1.875rem] text-davysgrey text-sm border border-silversand outline-none p-2 rounded-md;
  background: url("../../images/home/down.svg") no-repeat 0 0;
  background-position: 100px;
}

/*
That style we're using for interest rate modal*/

.interestRateList {
  @apply flex justify-evenly mt-8 pt-4 border-dotted border-t-[0.25rem] flex-wrap gap-6;
}

.interestRateTitle {
  @apply text-4xl text-prussianBlue;
}

.interestRateTitle_Inner {
  @apply flex flex-col justify-around;
}

.interestRateSubTitle {
  @apply text-xs text-prussianBlue;
}

.interestRateCounts {
  @apply text-3xl text-prussianBlue font-bold;
}

.interestRatePointCounts {
  @apply text-lg text-prussianBlue font-bold;
}
