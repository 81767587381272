.header {
  @apply border-b border-brightgray pb-4 relative;
}

@screen maxLg {
  .header {
    @apply pb-4 mt-0 flex items-center justify-between;
  }
}

.header h3 {
  font-family: "Inter-SemiBold";
  letter-spacing: 0.0063rem;
  @apply font-bold text-[1.125rem] leading-[1.5625rem] text-darkGreyBlue;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.header p {
  font-family: "Inter-Regular";
  letter-spacing: 0.025rem;
  @apply font-medium text-[0.75rem] leading-[1.125rem] text-mediumseagreen;
}

.msgBody p {
  letter-spacing: 0.025rem;
  @apply text-[0.75rem] leading-[1.125rem] text-crayolascadetblue;
}

.msgBody ul {
  @apply pb-10 pt-5;
}

@screen maxMd {
  .msgBody {
    height: calc(100vh - 16.25rem);
  }
}

.msgBody {
  @apply pb-3;
  height: calc(100vh - 19.375rem);
}

@screen maxLg {
  .msgBody {
    height: calc(100vh - 20.625rem);
  }
}

@screen maxMd {
  .msgBody ul {
    @apply pb-0 pt-5;
  }
}

@screen md {
  .msgBody ul {
    height: calc(100vh - 15rem);
  }
}

@screen lg {
  .msgBody ul {
    height: calc(100vh - 20.1875rem);
  }
}

.msgBody ul p {
  letter-spacing: 0.025rem;
  @apply text-[0.75rem] leading-[1.125rem] mb-3 text-crayolascadetblue;
}

.msgBody ul li.msgSent,
.imageUploadPlaceholder {
  font-family: "Inter-Regular";

  border: 0.0625rem solid;
  @apply p-4 text-lotion font-medium text-[0.875rem] leading-[1.3125rem] table;
  border-radius: 1.5rem 0rem 1.5rem 1.5rem;
  -webkit-border-radius: 1.5rem 0rem 1.5rem 1.5rem;
  -khtml-border-radius: 1.5rem 0rem 1.5rem 1.5rem;
  min-width: 9.4375rem;
}

.msgBody ul li.msgReceive {
  font-family: "Inter-Regular";
  margin-right: auto;
  display: table;
  border: 0.0625rem solid #f4f5f6;
  background-color: #f4f5f6;
  padding: 1rem;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.3125rem;
  color: #777e91;
  border-radius: 1.5rem 1.5rem 1.5rem 0rem;
  min-width: 9.4375rem;
}

.msgBody ul li {
  @apply mx-3;
  max-width: 17.5rem;
}

.msgFooter {
  @apply relative flex items-center;
}

.msgFooter input {
  width: 100%;
  font-family: "Inter-Regular";
  outline: 0;
  @apply text-[0.875rem] text-left leading-[1.3125rem] py-[1.125rem] text-charlestongreen;
}

.msgFooter input::placeholder {
  @apply text-center;
}

.msgFooter input:focus {
  @apply text-left;
}

.msgFooter > img {
  @apply absolute mx-auto left-0 z-10;
  right: 15.75rem;
}

.headingMd {
  letter-spacing: 0.0063rem;
  @apply text-[1.125rem] leading-[1.25rem] text-darkGreyBlue max-w-[13.125rem] w-max capitalize;
  font-family: "Inter-Medium";
}

.cardHeader {
  @apply md:mt-[3.125rem] md:mx-[5rem] m-4 maxMd:flex items-center;
}

.CardBody {
  @apply flex flex-col gap-3;
}

@screen maxSm {
  .CardBody {
    @apply p-0;
    overflow: hidden;
  }

  .CardContent {
    width: auto !important;
  }

  .CardBody li {
    @apply !py-[0.8rem];
  }
}

.CardBody li {
  @apply border-t border-lightAsh py-[1.15625rem] gap-[0.625rem] pl-6 cursor-pointer;
  border-width: inherit;
  border: 0.0625rem solid transparent;
}

@media (min-width: 1024px) {
  .CardBody li {
    max-width: 28.0625rem;
  }
}

.CardBodyCol2 p {
  @apply text-[0.875rem] leading-[1.4375rem] text-darkGreyBlue;
  font-family: "Inter-Regular";
}

.CardContent {
  width: 13.125rem;
}

@screen md {
  .CardBodyCol2 {
    flex: 3;
  }
}

@screen maxMd {
  .CardBodyCol2 {
    flex: 3;
  }
}

.CardBodyCol2 p,
h1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.CardBodyTime {
  @apply text-[0.75rem] leading-[1.125rem] text-silverMist font-medium;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.social_icon {
  @apply flex flex-wrap items-center absolute top-2.5 right-3;
}

.social_icon button:hover svg path {
  @apply stroke-jacarta transition-all;
}

.msgSent:last-child {
  /* background-color: red !important; */
}

.mainContent {
  @apply pl-3 pt-7;
}

.chatHeading {
  letter-spacing: 0.0063rem;
  @apply text-[1.125rem] leading-[1.5625rem] text-charlestongreen font-bold;
}

.chatBox .online {
  @apply text-mediumseagreen;
}

@media (max-width: 1023px) {
  .chatBox {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .chatList {
    overflow: hidden;
    width: calc(100vw - 0.3125rem);
    margin: auto;
  }
}

.chatBoat {
  @apply flex flex-col w-full pt-[4.625rem];
  border-top: 0.0625rem solid #e6e8ec;
}

.chatList {
  border: 0.0625rem solid #c4c4c4 !important;
  box-shadow: 0rem 0rem 0rem rgb(0 0 0 / 25%) !important;
}

.chatText {
  @apply pt-[1.5rem] pb-24 flex flex-col gap-[1.25rem];
}

.chatText1 p {
  @apply text-sm text-lotion p-4 font-medium bg-jacarta max-w-fit w-[17.5rem];
  text-align: left;
  border-radius: 1.5rem 0rem 1.5rem 1.5rem;
}

.chatText2 p {
  @apply text-sm text-slategrey p-4 font-medium bg-whitesmoke max-w-fit w-[17.5rem];
  text-align: left;
  border-radius: 1.5rem 1.5rem 1.5rem 0rem;
}

.message_box {
  height: calc(100lvh - 8.4375rem);
  padding-left: 3.1875rem;
}

.message_word {
  @apply text-base;
  word-break: break-word;
}

/* ---------------Message-Seller-Modal---------------- */

.messageSeller_modal {
  @apply overflow-y-auto overflow-x-hidden fixed top-0 bottom-0 left-0 right-0 z-[300] flex items-center;
}

@media (max-width: 550px) {
  .messageSeller_modal {
    @apply left-[0.9375rem] right-[0.9375rem];
  }
}

.messageSeller_modal::before {
  content: "";
  background-color: rgba(0, 0, 0, 0.5);
  @apply top-0 left-0 right-0 bottom-0 fixed w-full h-full z-20 overflow-hidden;
}

.messageSeller_modal .messageSeller_modalContent {
  box-shadow: 0rem 0.0625rem 0.875rem rgba(0, 0, 0, 0.16);
  @apply my-8 mx-auto bg-white w-full z-20 rounded-lg maxMd:p-4 left-0 right-0 absolute max-w-[32.5rem];
}

.delete_icon:hover .delete_icon_button {
  display: block !important;
}
