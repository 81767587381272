@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

body {
  font-family: "Inter-Regular";
  @apply box-border w-full m-0 text-eerieblack bg-whitesmoke;
  -webkit-tap-highlight-color: transparent;
}

#portal {
  @apply fixed bottom-5 right-8 maxMd:right-0 maxMd:bottom-0 z-10;
}

.font-inter-light {
  font-family: "Inter-Light";
  font-weight: 300;
}

.font-inter-regular {
  font-family: "Inter-Regular" !important;
}

.font-inter-medium {
  font-family: "Inter-Medium";
}

.font-inter-semiBold {
  font-family: "Inter-SemiBold";
}

.font-inter-bold {
  font-family: "Inter-Bold";
}

.font-inter-extra-bold {
  font-family: "Inter-Extra";
}

.title_lg {
  @apply text-davygrey font-bold;
  font-family: "Inter-Regular";
  font-size: 2.5rem;
  line-height: 3.375rem;
}

@screen maxMd {
  .title_lg {
    font-size: 1.625rem;
    line-height: 1.5rem;
  }
}

.title-md {
  font-size: 1.563rem;
  line-height: 1.813rem;
  @apply text-blackcoral font-medium;
}

@screen maxMd {
  .title-md {
    font-size: 1.25rem;
    line-height: 1.3em;
  }
}

.btn-primary {
  box-shadow: 0px 12px 21px 4px #4461f226;
  border-radius: 0.625rem;
  letter-spacing: 0.09em;
  font-size: 1.188rem;
  @apply !bg-steelblue text-white font-bold text-center p-3 h-10;
}

.btn-info {
  @apply !bg-darkturquoise text-white font-bold text-center px-3 py-2 rounded-[0.625rem] transition;
}

@screen maxMd {
  .btn-primary {
    @apply p-2 text-base;
  }
}
.btn-outline {
  @apply border border-lavenderHaze hover:bg-steelblue hover:text-white py-3 px-8 w-full rounded-2xl;
}

.activeTitle:hover {
  @apply border-b-2 border-darkturquoise;
}
.activeTitle {
  @apply border-b-2 border-darkturquoise;
}
.activemenu:hover {
  @apply border-b-2 border-darkturquoise;
}
.activeTitleAdmin {
  @apply border-b-2 border-darkturquoise text-lg cursor-pointer leading-8;
  font-family: "Inter-Medium";
}
.activeTitleAdmin:hover {
  @apply border-darkturquoise;
}
.activemenu {
  @apply text-lg cursor-pointer leading-8 border-white;
  font-family: "Inter-Medium";
  border-bottom-width: 2px;
  border-color: transparent;
}
.active {
  @apply border-darkturquoise;
}
@screen maxMd {
  .activemenu {
    @apply text-base;
  }
  .activeTitleAdmin {
    @apply text-base;
  }
}

.activeOption {
  @apply relative;
}

h4 {
  @apply whitespace-nowrap;
}

.active-nav {
  @apply border-b-2 border-darkturquoise;
}

th,
td {
  @apply whitespace-nowrap;
}

.ActiveBtn {
  @apply bg-darkturquoise;
  font-family: "Inter-Regular";
}

.ActiveBtn:hover {
  background-color: #469dd3;
}

.PendingBtn {
  @apply bg-steelblue;
}

.PendingBtn:hover {
  background-color: #5858a4;
}

.DeclinedBtn {
  @apply bg-cinnabar;
}

.DeclinedBtn:hover {
  background-color: #910f0d;
}

.CloseBtn {
  @apply bg-oceanGreen;
}

.CloseBtn:hover {
  background-color: #b7b782;
}

table,
tbody {
  @apply w-full border-collapse;
  border-spacing: 0;
}
table tbody th div {
  @apply font-normal text-left;
}

.mobile-navigation {
  @apply hidden;
}

.bm-burger-button {
  @apply hidden;
}

.draggable-img-parent:not(:last-child) {
  @apply mr-4;
}

.draggable-img-parent.load:before {
  top: 37%;
  left: 37%;
}

@screen maxMd {
  .draggable-img-parent.load:before {
    top: 28%;
    left: 28%;
  }
}

.uploadParent {
  height: 100%;
  margin: 3.125rem auto 0 auto;
  box-shadow: 2px 2px 40px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  padding: 24px;

  @apply max-w-[429px] w-full relative rounded-[20px] maxMd:max-h-[380px] maxMd:p-[22px];
}

.uploadParent-mobile {
  height: 11.688rem;
  margin: 2.688rem auto 0 auto;
  box-shadow: 2px 2px 40px rgba(0, 0, 0, 0.25);
  padding: 1.563rem;
  @apply w-4/5 relative rounded-2xl;
}

@media only screen and (max-width: 1080px) {
  .uploadParent {
    width: 100%;
  }
}

.uploadButton-parent {
  top: 4.875rem;
  transform: translate(-50%, 10px);
  @apply absolute left-2/4;
}

.uploadButton-parent button {
  font-size: 1.375rem;
  padding: 16px 21px;
  line-height: 1rem;
  @apply text-white rounded-2xl bg-jacarta;
  font-family: "Inter-Medium";
}

.uploadButton-parent button:hover {
  @apply bg-rockblue;
}

.uploadButton-parent h4 {
  @apply text-blackcoral font-normal text-lg flex justify-center mt-5;
}

.uploadButton-parent-mobile {
  transform: translate(-50%, -50%);
  @apply absolute top-2/4 left-2/4;
}

.uploadButton-parent-mobile button {
  padding: 0.75rem 1.875rem;
  width: 10.813rem;
  @apply bg-steelblue font-semibold text-base text-white rounded-2xl;
}

.desktop-navigation {
  @apply fixed top-0 left-0 h-screen z-[10] flex maxMd:shadow-2xl;
}
.desktop-navigations::before {
  content: "";
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 200;
  height: 100%;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .mobile-navigation {
    @apply block;
  }

  .bm-burger-button {
    @apply block;
  }
}

.main {
  width: calc(100% - 89px);
  @apply ml-auto;
}

@screen maxXl {
  .main {
    width: calc(100% - 89px);
  }
}

@screen maxMd {
  .main {
    @apply w-full !top-0;
    /* overflow: inherit !important; */
  }
}
/* @screen maxLg {
  .main {
    overflow: initial !important;
  }
} */
.main-content {
  @apply w-full;
  padding: 2.063rem 55px 4rem 55px;
  margin-top: 0px;
}
.myadmin {
  padding: 0.7rem 2rem 4rem 2.3rem;
}

.no-padding-r {
  @apply pr-0;
}

@screen maxMd {
  .main-content {
    @apply px-6 pt-24 pb-16;
  }
  .myadmin {
    @apply pt-6;
  }
}
@screen maxSm {
  .main-content {
    @apply px-3;
  }
}
.light-button {
  padding: 0.313rem 0.938rem;
  transition: all 0.3s ease-in-out;
  @apply border-2 border-jacarta bg-white text-jacarta mr-4 font-bold rounded-lg;
  font-family: "Inter-Bold";
}

.light-button:hover {
  transition: all 0.3s ease-in-out;
  @apply sm:bg-jacarta sm:text-white;
}

.dark-button {
  @apply font-bold rounded-lg text-white !bg-jacarta py-2 px-4;
  font-family: "Inter-Bold";
}

.blue-button {
  @apply font-semibold rounded-lg text-white !bg-metallicBlue py-2 px-4 hover:opacity-[0.9] transition-all;
  font-family: "Inter-SemiBold";
}

@screen md {
  .dark-button:hover {
    transition: all 0.3s ease-in-out;
    background: #40425ed9 !important;
  }
}

.draggable-img {
  width: 7.5rem;
  height: 7.5rem;
  @apply rounded-2xl object-cover;
}

.dragIcons-parent {
  right: 0.313rem;
  bottom: 0.313rem;
  background: rgba(255, 255, 255, 0.79);
  border-radius: 0.313rem;
  @apply flex justify-around items-center absolute py-1.5 px-2;
}

.mobile-next-btn {
  margin-bottom: 2.188rem;
  @apply !bg-steelblue w-full py-4 rounded-2xl text-white font-bold text-base text-center;
}

.input-datepicker {
  padding: 0.938rem 1.25rem;
  border: 1px solid rgba(0, 0, 0, 0.54);
  @apply w-full rounded-2xl mt-4 text-slategrey;
}

@screen maxMd {
  .input-datepicker {
    @apply py-3.5;
  }
}

.nav-item {
  transition: all 0.4s ease-in-out;
  font-size: 1.125rem;
  line-height: 1.313rem;
  font-family: "Inter-Regular";
  @apply relative;
}

.nav-item::after {
  content: "";
  transition: all 0.4s ease-in-out;
  @apply border-t-2 border-darkturquoise absolute block w-0 mt-2;
}

.disabled {
  @apply !bg-slategrey pointer-events-none;
}

button:disabled {
  @apply pointer-events-none opacity-[0.6];
}

.form-group {
  @apply flex flex-col;
}

.form-label {
  @apply block mb-2 text-[0.938rem] font-medium text-blackcoral;
  font-family: "Inter-SemiBold";
}

.form-control {
  @apply rounded-[8px] outline-none bg-whitesmoke  px-4 focus:border-darkturquoise border focus:bg-white h-[40px];
}
.form-control:focus-visible {
  outline: inherit;
}

.form_control_input {
  @apply !bg-[#F9FAFB];
}

.form-select {
  @apply relative;
}

textarea:focus-visible {
  @apply rounded-lg outline outline-darkturquoise;
}

.form-select:before {
  content: "";
  top: 49px;
  right: 1.125rem;
  background: url("../images/icons/down.png") no-repeat center / contain;
  height: 0.313rem;
  @apply pointer-events-none w-2.5 absolute;
}

.form-select select {
  @apply bg-whitesmoke  pr-10 pl-4 rounded-[8px] outline-none w-full appearance-none focus:border-darkturquoise border h-[40px];
}

.date-icon::before {
  content: "";
  background: url("../images/dateicon.png") no-repeat center / contain;
  @apply pointer-events-none w-4 h-4 absolute z-10 top-[2.65rem] right-[1.15rem];
}

@screen md {
  .hamburger_btn {
    @apply hidden;
  }
}

.hamburger_btn {
  @apply fixed bg-white w-full pl-3 z-10 flex py-3.5 items-center h-16 shadow-md;
}

.hamburger_btn button {
  transform: translateY(-50%);
  @apply absolute top-2/4;
}

.show .hamburger_btn {
  @apply hidden;
}

.show .desktop-navigation {
  @apply block;
}
.overlay {
  @apply !overflow-hidden;
}
.overlay:before {
  content: "";
  background-color: rgba(0, 0, 0, 0.5);
  @apply top-0 left-0 right-0 bottom-0 fixed w-full h-full z-[200] backdrop-blur-[6px];
}

.overlayHidden:before {
  @apply bg-transparent;
}

.down_arrow::before {
  content: "";
  background: url("../images/icons/downarrow.svg") no-repeat center / contain;
  top: 1.375rem;
  @apply w-3.5 h-2 absolute right-3.5 pointer-events-none;
}

.arrow_icon::before {
  content: "";
  background: url("../images/icons/down.png") no-repeat center / contain;
  height: 0.313rem;
  top: 3.25rem;
  @apply w-2.5 absolute pointer-events-none right-4;
}

.arrow_icon--down::before {
  @apply top-[3.65rem];
}

.arrow_iconsignup::before {
  content: "";
  background: url("../images/icons/down.png") no-repeat center / contain;
  height: 0.313rem;
  top: 20px;
  @apply w-2.5 absolute pointer-events-none right-4;
}

.arrow_iconsignup--down::before {
  @apply top-[34px];
}

.date_icon::before {
  content: "";
  background: url("../images/icons/date.svg") no-repeat center / contain;
  width: 1.125rem;
  height: 1.125rem;
  @apply absolute pointer-events-none top-11 right-4 z-[1];
}

.factsRowSelect {
  @apply w-full;
}

.factsRowSelect label {
  @apply font-medium text-onyx block;
  font-size: 0.813rem;
  line-height: 1.313rem;
}

.factsRowSelect input {
  padding: 8px 16px 8px 40px;
  @apply border border-gray-300 rounded-md w-full outline-none text-sm;
}

.dollerSign {
  background-color: rgb(243, 244, 246);
  border-right: 1px solid rgb(209, 213, 219);
  bottom: 1px;
  left: 1px;
  top: 22px;
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
  @apply px-3 py-1 text-base absolute flex justify-center items-center;
}

.form-group .react-datepicker__triangle {
  @apply hidden;
}

.custom-address {
  width: calc(100% - 70px);
  white-space: initial;
}

.mobile_address {
  width: calc(100% - 74px);
  @apply flex flex-wrap justify-between;
}

@media (max-width: 950px) {
  .mobile_address {
    @apply w-full items-center;
  }
}

.pagination {
  gap: 5px;
  @apply list-none flex justify-end items-center mt-8 text-base p-4 !pt-0;
}

@screen maxMd {
  .pagination {
    @apply justify-start;
  }
}

.pagination .page-num {
  border-radius: 3px;
  @apply py-1.5 px-3 cursor-pointer font-normal block;
}

.pagination .page-num:hover {
  @apply bg-gray-200;
}

.page-disable {
  @apply bg-white;
}

.page-num.page-link-disable {
  @apply text-gray-500 hover:bg-white hover:text-gray-500 cursor-default;
}

.pagination .active {
  @apply bg-jacarta text-white rounded;
}

.active:hover .page-num {
  transition: all 0.3s ease-in-out;
  background: #40425ed9 !important;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 1.625rem;
  height: 1.625rem;
  animation: spin 2s linear infinite;
}
.common_loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 2s linear infinite;
}
.button_loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader-content .loader {
  width: 3.75rem;
  height: 3.75rem;
  border: 7px solid #f3f3f3;
  border-top: 7px solid #3498db;
}

.mobile_address > div img {
  @apply w-10 h-10 object-cover items-center;
}

.load:before {
  content: "";
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px !important;
  height: 30px !important;
  animation: spin 2s linear infinite;
  left: 30%;
  @apply absolute z-10 top-1/4;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader-content-loader {
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  @apply fixed top-2/4 left-2/4 w-full h-full z-10;
}

.loader-content-loader .loader {
  width: 3.75rem;
  height: 3.75rem;
  border: 0.375rem solid #f3f3f3;
  border-top: 0.375rem solid #3498db;
}

.isActive {
  @apply !border-chineseblack !border-2;
}

.gm-ui-hover-effect {
  @apply hidden !important;
}

.card {
  box-shadow: 0px 0px 0px rgb(0 0 0 / 18%);
  @apply bg-white rounded-[5px] p-4 border  border-silversand;
}

.my-table thead tr:first-child {
  @apply bg-jacarta;
}

.my-table th {
  @apply text-white text-base leading-[18px] text-left font-normal px-3 py-3.5;
}

.my-table td {
  font-family: "Inter-Regular";
  @apply text-sm py-6 leading-[16px] px-3 text-blackcoral;
}

@screen maxMd {
  .custom-btn button {
    @apply px-3;
  }
}

.dropdown-card {
  @apply absolute px-6 rounded-md z-10 border-transparent shadow-cardShadow;
  width: 300px;
}

.dropdown-card select,
.dropdown-card input {
  @apply rounded-md py-2 text-gray-500 font-light;
}

.dropdown-card .down_arrow::before {
  @apply top-4;
}
.more_lot_dropdown::before {
  @apply !top-[18px] !right-[10px];
}

@screen md {
  .dropdown-card {
    top: 3rem;
  }
}

@screen xl {
  .dropdown-card {
    top: 3.6rem;
  }
}

@screen minMaxMd {
  .datepicker-card .form-label {
    min-height: 40px;
    @apply flex items-end;
  }

  .datepicker-card .date-icon::before {
    top: 4rem;
  }
}

.down_arrow .select__value-container {
  @apply rounded-2xl outline-none  px-4;
}

.down_arrow .select__control {
  @apply rounded-[8px] outline-none !bg-whitesmoke !border;
}

.down_arrow .select__indicator-separator {
  @apply bg-whitesmoke;
}

.google_btn {
  @apply border-steelblue border-2 bg-white py-2 px-5 rounded-full font-semibold text-steelblue relative z-10;
}

.google_btn::after {
  content: "";
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 13px solid #3382a8;
  position: absolute;
  bottom: -14px;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}

.google_btn::before {
  content: "";
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 11px solid #fff;
  position: absolute;
  bottom: -11px;
  left: 50%;
  transform: translateX(-50%);
}

.min_price {
  @apply mb-1;
}

.factsRowSelect.down_arrow::before {
  top: 39px;
}

.custom_filter .down_arrow::before {
  top: 36px;
}

.custom_card {
  max-width: 1035px;
  @apply w-full;
}

@media (min-width: 444px) and (max-width: 767px) {
  .gm-style .gm-style-iw-d {
    @apply !overflow-y-hidden;
    max-height: 214px !important;
  }

  .gm-style .gm-style-iw-c {
    max-height: 220px !important;
  }
}

.montserrat {
  font-family: "Montserrat";
}

.select .selectBtn {
  @apply relative text-right cursor-pointer rounded-lg bg-lotion border border-brightgray  pl-3 maxMd:py-2 maxMd:pl-3  maxMd:pr-[30px];
}

@screen maxMd {
  .select .selectBtn:after {
    content: "";
    background: url("../images/updown.svg") no-repeat center / cover;
    height: 18px;
    @apply absolute right-[10px] top-2.5 w-3.5;
  }
}

.select .selectBtn.toggle {
  border-radius: 3px 3px 0 0;
}

.select .selectBtn.toggle:after {
  -webkit-transform: translateY(-50%) rotate(-135deg);
  transform: translateY(-50%) rotate(-135deg);
}

.select .selectDropdown {
  @apply absolute right-0 overflow-hidden bg-white invisible;
  top: 100%;
  width: 200px;
  border-radius: 5px;
  z-index: 1;
  transform: scale(1, 0);
  transform-origin: top center;
  transition: 0.2s ease;
  box-shadow: 2px 2px 14px 2px rgba(0, 0, 0, 0.25);
}

.select .selectDropdown .option {
  padding: 11px;
  @apply cursor-pointer;
}

.select .selectDropdown .option:hover {
  background: #f8f8f8;
}

.select .selectDropdown.toggle {
  @apply visible maxMd:mt-2;
  transform: scale(1, 1);
}

.custom-icon .date_icon::before {
  @apply top-10;
}
.custom-icons .date_icon::before {
  @apply top-14;
}

.sort_downarrow .selectBtn {
  @apply bg-transparent border-transparent;
  padding-left: 2px;
  padding-bottom: 0px;
}

.sort_downarrow .selectBtn {
  letter-spacing: 0.05rem;
  @apply text-davysgrey text-sm;
}

.property_table {
  width: calc(100% - 62px);
  white-space: break-spaces;
  @apply text-left;
}

.price-tag {
  @apply bg-white relative text-crayolasouterspace text-sm py-1.5 px-5 border-2 border-crayolasouterspace rounded-full font-semibold;
}

.price-tag::after {
  content: "";
  transform: translate(-50%, 0);
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #fff;
  bottom: -9px;
  @apply absolute left-1/2;
}
.price-tag::before {
  content: "";
  transform: translate(-50%, 0);
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 12px solid #30324a;
  @apply absolute top-full left-1/2;
}

.custom_design {
  @apply fixed top-1/2 left-1/2 overflow-hidden bg-whitesmoke;
  transform: translate(-50%, -50%);
  z-index: 99999;
}
.custom_offers {
  width: calc(100% - 118px);
}

.slick-dots {
  bottom: 16px;
}
.slick-dots li button {
  @apply p-0 w-3 h-3;
}
.slick-dots li button:before {
  @apply opacity-70 rounded-full text-steelblue;
  font-size: 12px;
  line-height: initial;
  height: 10px;
  width: 10px;
}
.slick-dots li.slick-active button:before {
  @apply !text-darkturquoise opacity-90;
}
.slick-slider.slick-initialized .slick-prev:before {
  display: none;
}
.slick-slider .slick-next,
.slick-slider .slick-prev {
  display: none !important;
}
.slick-slide {
  max-height: 350px;
}
.slick-slide div img {
  max-height: 270px;
  object-fit: cover;
}
@screen maxMd {
  .slick-track {
    @apply flex items-center gap-[5px];
  }
  .slick-slide div img {
    min-height: 200px;
    margin-top: 80px;
    border-radius: 5px;

    @apply object-left;
  }
}

.badge {
  @apply bg-darkturquoise rounded-full text-white flex items-center justify-center  ml-auto py-[3px] px-[9px];
  font-size: 0.75rem;
}

.inter-bold {
  font-family: "Inter-Bold" !important;
  @apply !text-black;
}
.custom_size {
  width: calc(100% - 50px);
}
.emoji-container {
  @apply !absolute bottom-full;
}
.emoji-container aside.EmojiPickerReact.epr-main {
  @apply !w-full;
}
.placeholder_img {
  @apply absolute w-10 h-10 top-0 left-0;
}
.placeholder_img::before {
  content: "";
  @apply bg-whitesmoke rounded-full w-10 h-10 top-1 left-3.5 absolute top-0 left-0;
}

.offer_msg {
  font-size: 20px;
  text-transform: uppercase;
  color: cadetblue;
  font-weight: 800;
}
.scroll_icon::before {
  content: "";
  background: url("../images/down_arrow.svg") no-repeat center / contain !important;
  width: 13px;
  height: 10px;
  left: 4px;
  top: 5px;
  position: absolute;
}

.messages_card {
  overflow-y: auto;
  height: calc(100vh - 276px);
}

@screen maxMd {
  .messages_card.card {
    @apply absolute top-0 w-full left-0 right-0 rounded-none h-screen;
  }
  .messages_card {
    max-height: 820px;
  }
}

.arrow_icon_value .arrow_icon::before {
  top: 19px;
}

.status__btn {
  @apply px-5 py-1.5 rounded text-white inline-block w-[118px];
}
@media (max-width: 950px) {
  .status__btn {
    @apply text-sm rounded text-white mt-2 px-5 py-1 inline-block  w-[115px] text-center;
  }
}

@screen xl {
  .property_searchbar {
    @apply !absolute right-[27px] top-[31px];
  }
}

@screen lg {
  .property_searchbar--offers {
    @apply !absolute right-[27px] top-[31px];
  }
}

.custom_toggle-switch .toggle-switch .inner:before {
  font-size: 0.875rem;
  padding-left: 7px;
  padding-right: 6px;
}
.custom_toggle-switch .toggle-switch .inner:after {
  height: 32px;
}

.signup_form select {
  -webkit-appearance: none;
}
@media (max-width: 400px) {
  .signup_form {
    @apply grid-cols-1 w-full;
  }
  .custom_toggle-switch {
    @apply flex-wrap gap-4 justify-between;
  }
}

.tooltip__profile:hover svg .people_path {
  transition: 0.3s all;
}
.tooltip__profile svg {
  fill: transparent !important;
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .sort_menu {
    @apply !justify-end;
  }
}
.tooltip__profile:hover svg {
  fill: transparent !important;
}
a:hover .tooltip__profile svg .people_path {
  transition: 0.3s all;
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .sort_menu {
    @apply !justify-end;
  }
}

.usernameelips {
  max-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.propertyelips {
  max-width: 300px;
  width: 100%;
  text-wrap: balance;
}

@screen minMaxLg {
  .offerUserName {
    max-width: 110px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.tooltip__profile svg {
  fill: transparent;
}
.tooltip__profile2.active::before {
  @apply absolute w-[50px] h-[50px] top-[-12px] rounded-[5px];
  content: "";
  background: #f3f4f5;
}
@screen maxMd {
  .tooltip__profile2.active::before {
    @apply w-10 h-10 top-[-9px] right-[-8px];
  }
}
.tooltip__profile22.active::before {
  position: inherit;
}
.noactive::before {
  display: none;
}
.items:hover button,
.dropDown button:hover {
  @apply text-gray-700 w-full text-left bg-[#EAFAFC];
  transition: all 0.4s ease-in-out;
}
.tooltip_offers {
  border-radius: 2px 6px 6px 2px !important;
}
.tooltip_dashboard {
  @apply !pt-[10px] !pb-[9px];
}
/* .tooltip__property{
  top: -1px !important;
} */
.tooltip__buyers {
  top: 1px !important;
}

@screen maxMd {
  .tooltip_dashboard {
    @apply !pt-[5px];
  }
  .tooltip__messages {
    top: -6px !important;
    padding-top: 4px !important;
  }
  .tooltip__messages {
    top: -8px !important;
    padding-top: 8px !important;
  }
}
.card1 h2 {
  font-family: "Inter-SemiBold";
}
.card1 p {
  @apply font-light;
  font-family: "Inter-Light" !important;
}

.form_control_buyer {
  border-radius: 0.625rem;
  padding: 0.875rem 2.5rem 0.875rem 0.875rem;
  --tw-bg-opacity: 1;
  background-color: rgb(234 240 247 / var(--tw-bg-opacity));
  border-width: 1px;
  outline: 2px solid transparent;
  outline-offset: 2px;
  width: 100%;
}
.tooltip__user {
  display: none;
}

.header-box-shadow {
  box-shadow: 0px 0.2px 1px rgba(0, 0, 0, 0.25);
  background: #fff;
}
@screen maxMd {
  .header-box-shadow {
    box-shadow: none;
  }
}

.textbox-backcolor input,
.textbox-backcolor select {
  @apply !bg-anflshwht;
}

.map_height {
  max-height: 540px !important;
}
.nav-item span.tooltip__profileMain {
  display: none !important;
}

@screen maxMd {
  .nav-item span.tooltip__profileMain {
    display: block !important;
    padding-top: 7px !important;
    left: 45px !important;
  }
  .tooltip__user {
    display: block;
  }
  .tooltip__profileMain {
    padding-top: 7px !important;
    left: 45px !important;
  }
}
.activeLabel {
  cursor: pointer;
}
.activeLabel span {
  transition: 0.3s all;
}
.activeLabel:hover span {
  color: darkturquoise;
  transition: 0.3s all;
}
@media (max-width: 1023px) {
  .messagebox {
    width: 100%;
  }
}

/* add when message box open width <=768px */
.main_content_message {
  padding: 10px !important;
  margin: 0px !important;
}

.header_none_message {
  display: none !important;
}

.message_body_message {
  /* height: calc(100vh - 134px) !important; */
  overflow-y: initial;
}
@screen maxSm {
  .message_body_message {
    /* overflow-y: inherit !important; */
  }
}
@media (max-width: 767px) {
  .main_content_message {
    padding: 0px !important;
    margin: 0px !important;
  }
}
.price_card {
  width: calc(33.33% - 6px);
}

.message_body_message::-webkit-scrollbar {
  width: 0.25rem;
}

.message_body_message::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
}
.logoadmin {
  @apply bg-white rounded-[30px];
  border: 1px solid #ffffff;
  padding: 2px 4px 2px 15px;
}

.nav-hidden {
  display: none;
}

/* Admin side user deopdown */

.dropdown_btn {
  @apply text-[8px] leading-[10px] text-crayolasouterspace mb-1 text-center py-[1px] px-2 w-[35px] h-[12px];
  font-style: normal;
  font-family: "Inter-Regular";
  border: 0.2px solid #40425e;
  border-radius: 2px;
  color: #40425e;
}

.dropdown_list .css-1nmdiq5-menu {
  @apply min-w-[381px] m-0 mt-[6px] py-[10px] px-0 bg-white rounded-[5px] z-10;
  border: 1px solid #c4c4c4;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.dropdown_list .css-13cymwt-control {
  @apply border-OsloGrey h-[2.6rem];
  box-shadow: none;
  cursor: pointer;
  border-radius: 0.375rem;
}

.dropdown_list .css-1n6sfyn-MenuList {
  @apply max-h-[302px] w-full mx-auto;
  /* max-w-[362px] */
}

.dropdown_list .css-1dimb5e-singleValue .user_email {
  @apply hidden;
}

.user_name {
  @apply text-base leading-4 text-crayolasouterspace mb-1;
  font-style: normal;
  font-family: "Inter-Medium";
}

.user_email {
  @apply text-xs leading-4 text-blackcoral mb-1;
  font-family: "Inter-Regular";
  font-style: normal;
}

.css-t3ipsp-control {
  @apply border-none;
  box-shadow: none;
}

.dropdown_list .css-tr4s17-option {
  @apply bg-transparent w-[356px] mx-auto p-0;
}
.css-t3ipsp-control {
  outline: none !important;
  border-color: #8a8a8a !important;
  box-shadow: 0 0 0 0px #8a8a8a !important;
  border-radius: 0.375rem !important;
}
.css-tr4s17-option span,
.css-tr4s17-option .dropdown_btn {
  @apply text-bondiblue border-bondiblue;
}
.react-select-custom {
  @apply px-[26px] pt-[9px] pb-[9px] hover:bg-darkmintgreen active:bg-darkmintgreen;
}

.css-10wo9uf-option:active {
  @apply bg-darkmintgreen !important;
}
.css-tr4s17-option .dropdown_btn {
  @apply bg-white;
}
.css-1dimb5e-singleValue .react-select-custom {
  background-color: transparent !important;
  @apply py-1 pl-[7px];
}
.css-1dimb5e-singleValue .react-select-custom div img {
  @apply w-[1.6rem] h-[1.6rem];
}
.css-1dimb5e-singleValue .react-select-custom div div {
  @apply h-[1.6rem]  w-full !max-w-[1.6rem] min-w-0;
}
.css-1dimb5e-singleValue .react-select-custom div div p {
  @apply text-xs;
}
.css-1dimb5e-singleValue .react-select-custom div .user_name {
  margin-bottom: 0;
}

.css-1u9des2-indicatorSeparator {
  @apply hidden;
}
.css-tj5bde-Svg {
  color: #9091a1;
  @apply w-[15px] h-[15px];
}
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
}

@media (max-width: 540px) {
  .searchbar_logo {
    @apply flex-wrap;
  }
  .dropdown_list {
    @apply w-full !important;
  }
  .adminLocation {
    @apply w-full;
  }
}
@media (max-width: 500px) {
  .admin_searchbar input[type="search"] {
    @apply w-full !important;
  }
}
@media (max-width: 767px) {
  .dropdown_list {
    @apply w-[325px];
  }
}
@media (max-width: 450px) {
  .dropdown_list .css-1n6sfyn-MenuList {
    @apply max-w-[362px] !important;
    max-height: 172px;
  }
  .dropdown_list .css-1nmdiq5-menu {
    @apply overflow-x-auto !important;
    min-width: 260px;
  }
  .react-select-custom {
    padding-left: 16px;
    padding-right: 16px;
  }
}
.chatActive {
  border-color: #40425e !important;
}
.addbtn {
  @apply text-steelblue underline block ml-auto mb-[15px];
}
.locationfield {
  position: relative;
}
.locationcard {
  @apply max-h-[261px] overflow-y-auto pb-[6px];
}
.userdetails h4 {
  @apply text-ironsidegrey text-[22px] font-medium leading-[22px];
  word-wrap: break-word;
  white-space: break-spaces;
  word-break: break-all;
}
.userdetails p {
  @apply text-rangoongreen text-base font-medium leading-[1.5rem] mb-[10px];
}
.main {
  overflow: auto;
  position: absolute;
  top: 65px;
  right: 0;
  bottom: 0;
}

main {
  overflow: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
}
.nomessage {
  background-color: rgb(255 255 255);
  border-color: rgb(196 196 196);
  border-radius: 5px;
  border-width: 1px;
  box-shadow: 0 0 0 rgb(0 0 0 / 18%);
  overflow-y: auto;
  padding: 2rem 1.5rem 2rem;
}
.locationscroll::-webkit-scrollbar {
  width: 0.25rem;
}
::-webkit-scrollbar {
  width: 0.25rem;
}
.scrollbardesign::-webkit-scrollbar {
  height: 0.25rem;
}
.locationscroll::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
}

.cardscroll::-webkit-scrollbar,
.tableData::-webkit-scrollbar {
  height: 0.25rem;
}
.show {
  display: block !important;
}

.property-header {
  background-color: inherit !important;
}
.inputDates .react-datepicker-wrapper {
  width: calc(100% - 20px) !important;
  margin-right: 10px;
  margin-left: 10px;
}
.filterDates .react-datepicker-wrapper {
  @apply mr-[1.8125rem] !w-[calc(100%-1.875rem)];
}
.gm-style .gm-style-iw-d {
  @apply !max-h-full;
}
.gm-style .gm-style-iw-c {
  @apply !max-h-[initial];
}
.userFilter .css-1ff06q0-control,
.css-7pzezp-control {
  border: 1px solid rgba(64, 66, 94, 0.58) !important;
  border-radius: 6px !important;
  box-shadow: inherit !important;
  height: 40px;
}
.css-7pzezp-control:hover {
  border: 1px solid rgba(64, 66, 94, 0.58) !important;
  border-radius: 6px !important;
}
@screen lg {
  .admin_subnavbar {
    @apply absolute top-[72px] right-0;
  }
}
@screen maxLg {
  .admin_subnavbar {
    @apply relative top-0 w-fit mb-1;
  }
}
@screen maxSm {
  .admin_subnavbar {
    @apply relative top-0 w-fit mb-1;
  }
}

.chatHeight {
  height: 100%;
}
.locationHeight {
  height: 100%;
}
@media (max-width: 520px) {
  .cardGrid {
    @apply !grid-cols-1;
  }
}
@media (min-width: 768px) and (max-width: 950px) {
  .propertyBox {
    margin-top: 10px !important;
  }
}

.btnNotFound {
  height: auto;
}
.editIcon img,
.msgIcon img {
  border-radius: inherit !important;
}
.card .cardLable {
  font-size: 11px;
  padding: 2px 6px;
  border-radius: 3px 3px;
}
.cardLableAdmin {
  @apply text-[#414059] bg-[#d0e8f5];
}
.cardLableBuyer {
  @apply text-[#47C693] bg-[#47c69347];
}
.cardLableSeller {
  @apply text-[#1AC8DB] bg-[#c0f0f794];
}
.cardLableAgent {
  @apply text-[#FB6154] bg-[#ff0f002b];
}
.cardLableHomeOwners {
  @apply text-[#367BF5] bg-[#E5EFFF];
}

.listingCard {
  display: inline-block;
  font-size: 11px;
  padding: 0px 6px;
  border: 1px solid transparent;
  border-radius: 3px;
}

@screen maxSm {
  .card .cardLable {
    top: 27px;
  }
}

.email-disable {
  background: #d5d5d5 !important;
  color: gray;
  pointer-events: none;
}
.customTable_body tr td:first-child {
  @apply pl-2.5;
}
.Toastify__toast-body > div:last-child {
  font-family: "Inter-Regular";
}
.modalTitle {
  @apply !text-blackcoral;
}
.css-14leha-control,
.css-ilbvbg-control,
.css-lh7ave-control {
  @apply !h-10;
}
.userIcon::before {
  display: none;
}
.input_search-property .properties_input[type="search"] {
  width: 100% !important;
}
.property__search-input {
  @apply flex flex-wrap gap-4 items-center maxMd:mt-0 justify-between absolute top-1 right-0;
}
@screen maxXl {
  .property__search-input {
    position: inherit;
    @apply mt-6;
  }
  .admin__property-tabs .react-tabs__tab-list {
    @apply !gap-1;
  }
}

.consent-form-file {
  width: max-content;
  border: 1px solid #ddd;
  background: #e5edf2;
  margin: 22px auto 0;
  padding: 0 10px;
  border-radius: 4px;
}

.consent-form-file span {
  display: inline;
}

.consent-form-file img {
  display: inline;
}

.menu-nav {
  @apply flex justify-between relative;
}
.menu-item {
  @apply text-[#FCC] p-[3px];
}
.menu-nav .three-dots:after {
  /* content:'\2807'; */
  content: url("../images/icons/threedot.svg");
  @apply cursor-pointer text-[#444] text-xl px-[5px] py-0;
}
/* .menu-nav a {
  @apply no-underline text-[white];
} */
.menu-nav .dropdown__inner {
  @apply px-4 py-[0.7rem] text-black hover:bg-gray-200 transition-all duration-300 flex items-center gap-2 min-w-[140px];
  border-bottom: 1px solid #e4d4d4;
}
.menu-nav .dropdown {
  @apply absolute  z-[-1] transition-opacity duration-[0.1s,z-index] delay-[0.1s,] right-[0.55rem] top-[1.75rem] z-10;
  outline: none;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  box-shadow: 2px 2px 14px 2px rgba(0, 0, 0, 0.25);
}
.menu-nav .dropdown-container:focus {
  outline: none;
}
.menu-nav .dropdown-container:focus .dropdown {
  @apply opacity-100 z-[100] transition-opacity duration-[0.2s,z-index] delay-[0.2s,] rounded;
}
.menu-nav .three-dots {
  transform: rotate(180deg);
}
/* .pac-container {
  top: 0px !important;
} */

.react-datepicker-wrapper {
  width: 100% !important;
}
.slick-track::before {
  @apply maxMd:hidden;
}

.is-error {
  @apply border !border-red-500;
}

/*
That we are using for open modal
*/

.modal-card {
  transform: translate(-50%, -50%);
  @apply gap-4 rounded-lg p-6 bg-white max-w-[32rem] w-full m-auto border-[0.063rem]  border-solid z-[1111] absolute top-1/2 left-1/2;
}
@screen maxSm {
  .modal-card {
    max-width: calc(100% - 1.563rem);
  }
}

.call_modal_heading {
  color: rgba(59, 66, 73, 0.78);

  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 32px;
}
.copy_button {
  padding: 12px 16px;

  border-radius: 5px;
  background-color: #193541 !important;
  color: white;
  width: 100%;

  box-shadow: 0px 1px 2px 0px rgba(31, 41, 55, 0.08);
}
.call_number {
  border-radius: 5px;
  border: 0.5px solid #c4c4c4 !important;
}
.inputGroup {
  @apply relative mb-[1.625rem] flex flex-col items-start;
}
.inputGroup input {
  @apply p-[17px] px-[3.35rem] rounded-[10px] bg-white border border-silversand w-full text-xs;
}
.message_inputbox {
  @apply p-[17px]  rounded-[10px] bg-white border border-silversand w-full text-xs;
  width: 100%;
  height: 219px;
}
.primary_buttton {
  width: 130px;
  padding: 8px 14px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background-color: #40425e !important;
  color: white;
  min-width: 130px;
}

.inputGroup input::placeholder {
  @apply text-[#525252] text-sm;
}

.inputGroup span {
  @apply absolute left-6;
}
.pac-container {
  @apply z-[9999];
}
.authInput_homehub {
  @apply flex items-center w-full;
}

/* 
That style we're using for homehub cash and offer common modal form...
*/

.form_input-group {
  @apply relative mb-[0.9rem] flex items-center;
}

.form_input-group input {
  @apply p-[14px] pl-[3.35rem] rounded-[5px] border border-silversand w-full text-[13px] h-[45px];
}

.form_input-group input::placeholder {
  @apply text-[#525252] text-xs;
}

.form_input-group span {
  @apply absolute left-[1.25rem] w-6 h-6;
}
.form_input-group span svg {
  @apply w-full h-full;
}

/* react select dropdown*/

.react-select__control {
  @apply !h-[2.375rem];
}

.react-select__option,
.react-select__option--is-focused,
.react-select_year__option,
.react-select_year__option--is-focused {
  @apply bg-gray-50 !cursor-pointer;
}

.react-select__option:hover,
.react-select__option--is-focused,
.react-select__option--is-selected,
.react-select_year__option:hover,
.react-select_year__option--is-focused,
.react-select_year__option--is-selected {
  @apply !bg-gray-50 !text-gray-700 cursor-pointer;
}

.react-select_year {
  @apply !h-[2.375rem];
}

.react-select_year__control {
  @apply !border-0 !h-[42px];
}

.react-select_year__indicator {
  @apply !hidden;
}

.react-select_year__value-container {
  @apply !p-1 w-[2.15rem];
}

.react-select_year__menu {
  @apply !w-12;
}

.react-select_year__option:hover,
.react-select_year__option--is-selected {
  @apply !bg-gray-50 !text-[#2FA2B9] cursor-pointer;
}

.react-select_year__placeholder {
  @apply text-xs;
}

.react-select_year__indicators {
  @apply !hidden;
}

.react-select_year__indicator-separator {
  border: 0;
}

.react-select__dropdown-indicator {
  @apply !px-[15px];
}

.react-select__dropdown-indicator svg {
  @apply !h-[15px] !w-[15px];
}

.eyeicon-toggle {
  @apply absolute right-3;
}

/* homehub buttons  */

.dark-button_homehub {
  @apply font-bold rounded-md text-white !bg-yankeesblue py-2.5 px-4;
}

@screen md {
  .dark-button_homehub:hover {
    @apply opacity-[0.8];
    transition: all 0.3s ease-in-out;
  }
}

/* that style we are using for estimated year select */
.estimated-year_select__control {
  @apply !border-yankeesblue !rounded-lg !h-[34px] !min-h-[34px];
}

.estimated-year_select__option {
  @apply !py-[0.65rem] !text-xs;
}

.estimated-year_select__option,
.estimated-year_select__option--is-focused {
  @apply bg-gray-50 !cursor-pointer;
}

.estimated-year_select__single-value {
  @apply !text-yankeesblue text-xs;
}

.estimated-year_select__indicator svg {
  @apply !text-yankeesblue h-[15px] w-[15px];
}

.estimated-year_select__menu {
  @apply !mt-1 !z-[3];
}

.estimated-year_select__option--is-focused,
.estimated-year_select__option--is-selected {
  @apply !bg-gray-50 !text-[#2FA2B9] cursor-pointer;
}

.estimated-year_select {
  @apply !h-[2.375rem];
}

.estimated-year_select__value-container {
  @apply !p-1 w-[3.4rem] !pl-[8px] !pr-0 !py-0;
}

.css-1k0rgcj-indicatorContainer {
  @apply !mr-0 p-2;
}

/* manrope font family for home-owner  */

.font-manrope {
  font-family: "Manrope";
}

.font-roboto {
  font-family: Roboto;
}

/* home-requests-table  */

.home-requests-table,
.home-requests-table td {
  @apply border-collapse border-[#DCE1E9] border;
}

.home-requests-table tbody tr:nth-child(even) {
  @apply bg-cultured;
}

.home-requests-table th {
  @apply !px-5;
}

.home-requests-table td {
  @apply !px-5;
}
.formError {
  @apply text-red-600 text-xs font-medium mt-1;
}

/* styles for profile items  */

.profileItems:hover button {
  @apply rounded-tr-md rounded-tl-md;
}

.logoutItems:hover button {
  @apply rounded-br-md rounded-bl-md;
}

/* that style we are using for auth  */
.btn-auth_ho {
  @apply btn-primary flex items-center font-inter-semiBold mx-auto hover:opacity-[0.9] transition;
  @apply text-xs;
}

/* that style we are using for properties pagination */
.prev-button,
.next-button {
  @apply !text-sm;
}

/* that style we are using for sidebar profile tab */
.profileFirstLetter {
  @apply w-[35px] h-[35px] object-cover rounded-[50%] bg-transparent border-yankeesblue border-[1px] flex justify-center text-yankeesblue text-[30px] items-center;
}
.acceptable_checkbox input {
  @apply mr-2;
}

/* tooltip design  */
.homeowner_contact {
  @apply flex justify-start items-center;
}
.homeowner_contact li {
  @apply mr-4 relative cursor-pointer z-[1] flex items-center justify-center;
}

.homeowner_contact li {
  @apply w-8 h-8 bg-[#F3F9FB] transition-all border border-moonStone rounded-md;
}

.action_bg-skyblue:hover::before,
.request_list-deleteIcon:hover::before {
  content: "";
  @apply absolute w-8 h-8 top-[-4px] left-[-6px] bg-[#DAF9FF] rounded-full z-0 transition-all;
}

.homeowner_contact li .tooltip {
  @apply absolute top-[-30px] left-[50%] rounded-[5px] opacity-0 invisible text-white bg-[#40425D] px-[0.625rem] py-[0.375rem] text-sm;
  transform: translateX(-25%);
  transition: all 0.3s ease;
}
.homeowner_contact li:hover .tooltip {
  @apply opacity-100 visible -top-[45px] text-xs;
}
.homeowner_contact li .arrow {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #1ac8db;
  color: #fff;
  padding: 6px 10px;
  border-radius: 5px;
  opacity: 0;
  visibility: hidden;
  font-size: 14px;
  transition: all 0.3s ease;
}

.homeowner_contact li:hover {
  @apply bg-azureishWhite;
  box-shadow: 3px 2px 45px 0px rgb(0 0 0 / 12%);
}
.homeowner_contact li svg {
  @apply w-[30px] h-[30px];
}
.homeowner_contact .tooltip:after {
  border-color: #40425d rgba(0, 0, 0, 0);
  border-style: solid;
  border-width: 10px 10px 0;
  bottom: -10px;
  content: "";
  display: block;
  left: 20%;
  position: absolute;
  width: 0;
}

.tab_design .react-tabs__tab-list {
  @apply border-none flex gap-4 flex-wrap;
}

.tab_design .react-tabs__tab {
  @apply border-none text-[1.125rem] cursor-pointer leading-8 maxXs:text-sm maxXs:leading-5;
  font-family: Inter-Medium;
}

.tab_design .react-tabs__tab--selected {
  @apply !border-b-2 !border-solid !border-darkturquoise !bg-transparent !border-r-0 !border-l-0 !border-t-0 w-fit outline-0;
}

.tab_design .react-tabs__tab:focus:after {
  @apply bg-transparent;
}
.asking_price {
  @apply !font-bold !text-xl;
  font-family: "Inter" !important;
}

.tab_message_design .react-tabs__tab-list {
  @apply !mb-[1px] !border-b-0 flex;
}
.contact-tab-design .react-tabs__tab-list {
  @apply !mb-0 !border-b flex maxXs:overflow-x-auto pb-0.5 scrollbardesign;
}

.tab_message_design .react-tabs__tab:focus:after {
  @apply bg-transparent;
}

.tab_message_design .react-tabs__tab {
  @apply px-5 py-2 border-[#aaa] bg-[#F9F9FA] border w-fit cursor-pointer border-b outline-0 rounded-none;
}
.contact-tab-design .react-tabs__tab {
  @apply border-transparent border-b-silversand border-b border-solid !px-0 text-center w-full;
}
.contact-tab-design .react-tabs__tab-list {
  @apply !border-b-0;
}

.tab_message_design .react-tabs__tab--selected {
  @apply bg-white border-b-white mb-[-1px];
}
.tab_message_design .message_card {
  @apply p-[0.85rem] bg-white  border border-[#C4C4C4] rounded-xl rounded-tl-none maxMd:p-2;
}
.message_card .offerdetails_footer {
  @apply px-4 pb-4 maxMd:px-1;
}
.offer_message_tab_details h3 {
  font-family: "Inter-Regular" !important;
}
.admin_tab_design {
  width: calc(100vw - 199px);
}
.tabs-filter .react-tabs__tab {
  @apply !border-transparent !border-[0.0313rem] text-lightSeaGreen bg-transparent py-2.5 px-[1.125rem] font-bold;
}

.tabs-filter .react-tabs__tab-list {
  @apply border-none flex flex-col gap-[0.9375rem];
}

.tabs-filter .react-tabs__tab--selected {
  @apply !border-[0.0313rem] !border-solid !border-lightSeaGreen text-lightSeaGreen bg-aquaMist py-2.5 px-[1.125rem] font-bold rounded-md;
}

.tabs-filter .react-tabs__tab:focus:after {
  @apply bg-transparent;
}

@screen maxMd {
  .admin_tab_design {
    width: calc(100vw - 48px);
  }
}

@screen maxXs {
  .admin_tab_design {
    width: calc(100vw - 24px);
  }
}

/* agent user selection  */
.agent_user-select {
  @apply border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-1.5 pl-2.5 outline-0 max-w-[8rem] relative appearance-none;
  background: url("../images/down.svg") #ffffff no-repeat 104px;
}

/* property Image edit design */

.hovereffect:hover .outer_image {
  opacity: 1;
  cursor: pointer;
  filter: brightness(1);
  transition: all 0.5s;
  transition: transform 0.5s;
}

.hovereffect:hover .property_image {
  filter: brightness(0.7);
  transition: all 0.35s;
}
.outer_image {
  @apply absolute top-0 left-0  w-full h-full flex justify-center items-center opacity-0;
  transition: opacity 0.35s, transform 0.35s;
  filter: brightness(0.7);
}
.group1 {
  @apply h-[38px] px-4 py-[10px] flex justify-center items-center gap-[10px] rounded-lg bg-[#6F6F6F];
}

/* homeowners table  */
.homeowner_properties {
  @apply flex flex-wrap sm:gap-4 items-center;
}

.homeowner_properties--users {
  @apply maxLg:mt-4 lg:absolute lg:top-1 lg:right-0 maxLg:max-w-full;
}

.homeowner_properties input[type="search"],
.homeowner_properties--users input[type="search"] {
  @apply max-w-[260px] maxLg:max-w-full;
}

.canvas_block canvas {
  @apply relative z-[2];
}

.agentRoleIcon path {
  @apply group-hover:fill-white;
}

.teamOwnerIcon path {
  @apply fill-white group-hover:fill-darkturquoise disabled;
}

/* that we're using for down arrow  */
.down_select-arrow {
  @apply bg-white rounded-[5px] relative outline-0 border-[0.5px] border-black;
}

.down_select-arrow::before {
  content: url("../images/dropdown.svg");
  @apply right-[9px] h-[0.5rem] absolute text-[25px] w-[0.875rem] pointer-events-none top-[-11px] z-[1];
}

.dropdown_list-contacts__control,
.dropdown_list-contacts-agent__control,
.dropdown_list-contacts-actions__control {
  @apply !h-8 !min-h-[2rem] !border-sonicSilver text-jacarta;
}
.userFilter .dropdown_list-contacts-actions__control {
  @apply !rounded;
}
.filter-dropdown .dropdown_list-contacts-actions__control {
  @apply !h-[2.5625rem] !min-h-[2.5625rem];
}
.dropdown_list-contacts__input-container,
.dropdown_list-contacts-agent__input-container,
.dropdown_list-contacts-actions__input-container {
  @apply !p-0 !text-sm;
}

.dropdown_list-contacts__placeholder,
.dropdown_list-contacts-agent__placeholder,
.dropdown_list-contacts-actions__placeholder,
.dropdown_list-contacts__value-container,
.dropdown_list-contacts-agent__value-container,
.dropdown_list-contacts-actions__value-container {
  @apply text-sm leading-md;
}

.dropdown_list-contacts-agent__menu,
.dropdown_list-contacts-actions__menu {
  @apply !w-40 !min-w-[10rem] !p-0 !z-[10];
}
.menu-dropdown .dropdown_list-contacts-actions__menu {
  @apply xs:-left-[5.125rem];
}
.dropdown_list-contacts__menu {
  @apply !w-[5rem] !min-w-[4.7rem] !p-0 !z-[3];
}

.select__row.dropdown_list-contacts__menu {
  @apply !w-[6.875rem] !min-w-[4.7rem] !p-0 !z-[3];
}

.archive-contacts .dropdown_list-contacts__menu-list,
.dropdown_list-contacts__menu-list,
.dropdown_list-contacts-agent__menu-list,
.dropdown_list-contacts-actions__menu-list {
  @apply !py-0 !max-h-32 overflow-y-auto;
}

.dropdown_list-contacts__indicator svg,
.dropdown_list-contacts-agent__indicator svg,
.dropdown_list-contacts-actions__indicator svg {
  @apply w-[0.9rem] h-[0.9rem];
}
.filtermodal .dropdown_list-contacts-agent__indicator svg {
  @apply hidden;
}
.verification {
  @apply flex flex-row items-center mx-auto w-full max-w-xs my-12 justify-center;
}

.verification input {
  @apply !w-16 h-16 maxMd:!w-12 maxMd:!h-12 flex flex-col items-center justify-center text-center px-5 maxMd:px-3 outline-none rounded-xl border border-coolGrey text-2xl text-metallicBlue font-medium;
}

.verificationError input {
  @apply !border-red-500;
}

.verification input[type="number"]::-webkit-inner-spin-button,
.verification input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.verification input[type="number"] {
  -moz-appearance: textfield;
}

.verification > div {
  @apply gap-6;
}

.vertical_tab {
  @apply flex justify-between gap-8 items-start w-full;
}

.vertical_tab--button {
  @apply py-2 px-3 text-xs font-medium text-left text-yankeesblue;
}
.vertical_tab .react-tabs__tab-list {
  @apply flex flex-col w-fit border-b-transparent maxLg:flex-row flex-wrap;
}

.contact-tab .react-tabs__tab--selected {
  @apply !border-b-2 !border-solid !border-darkturquoise !bg-transparent !border-r-0 !border-l-0 !border-t-0 w-fit outline-0;
}
.contact-tab .react-tabs__tab--selected::after {
  @apply hidden;
}

.vertical_tab .active-btn {
  @apply bg-antiFlashWhite border-transparent rounded-[5px] w-full whitespace-normal;
}

.vertical_tab .react-tabs__tab--selected::after {
  @apply hidden;
}

.vertical_tab .button-list {
  @apply items-baseline maxMd:hidden;
}

.common-table tbody tr:nth-child(even),
.setting-user-table tbody tr:nth-child(even) {
  @apply bg-cultured;
}

.common-table tbody tr,
.setting-user-table tbody tr {
  @apply align-top;
}

.setting-user-table thead tr th {
  @apply font-semibold;
}

.setting-user-table tbody tr td {
  @apply text-base;
}

.common-table thead tr th {
  @apply font-semibold;
}

.common-table tbody tr td {
  @apply text-base font-medium;
}

.gradient-text-agent {
  background: linear-gradient(
    269.98deg,
    #c0f0f7 -8.61%,
    #1ac8db -0.85%,
    #427baa 33.63%,
    #40425e 105.67%,
    #30345e 148.29%
  );
  font-family: "Inter-Bold";
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

/* plans design  */

.plans {
  border: 0.5px solid #c4c4c4;
  border-radius: 30px;
  padding: 34px;
  height: 615px;
}
/* .plans{
  @apply !w-[20rem] maxMd:!w-[16rem]
} */

.pro-plan {
  background: #30345e;
  transition: all 0.2s ease-in-out;
  box-shadow: 0px 10px 20px 0px #30345e;
  border: transparent;
}

.plans h3 {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.1em;
  color: #4282aa;
  margin-bottom: 0.5rem;
}

.pro-plan h3 {
  color: #c0f0f7;
}

.plans h1 {
  font-size: 30px;
  font-weight: 700;
  line-height: 62.5px;
  letter-spacing: 0.1em;
  color: #30345e;
  margin-bottom: 0.5rem;
}

.pro-plan h1 {
  color: white;
}

.plans h1 span {
  font-size: 16px;
}

.plans .plans-desc {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  /* height: 380px; */
  height: calc(100% - 100px);
}
.plans .plans-desc span {
  @apply text-left tracking-widest text-[0.8438rem] font-medium;
}

.plans .plans-desc ul li {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-bottom: 1rem;
  color: #30345e;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}

.pro-plan .plans-desc ul li svg path {
  fill: #c0f0f7;
}

.pro-plan .plans-desc ul li {
  color: white;
}
.pro-plan .plans-desc .choose-plan button {
  background: #c0f0f7;
  color: #30345e;
}
.choose-plan button:hover {
  background: #fff;
  color: #30345e;
}
.billing-hover-btn:hover {
  background: #30345e !important;
  color: white !important;
}

.slick-slide {
  @apply !max-h-full;
}
.slick-list {
  @apply maxMd:!px-4;
}

.slick-track {
  @apply flex justify-start items-center   pr-[2.75rem]    gap-6   mb-[3.875rem];
}
.slider-container {
  @apply overflow-hidden;
}
/* .slider-container .slick-track {
  @apply mb-0;
} */
.slick-slider .slick-track,
.slick-slider .slick-list,
.slick-initialized {
  @apply flex w-full;
}
.slider-buttons {
  @apply flex justify-center mb-[3.3125rem] gap-6;
}

.active-btn {
  @apply bg-antiFlashWhite border-transparent rounded-[5px] w-fit whitespace-nowrap;
}

.setting-menu:hover::before {
  position: absolute;
  top: -9px;
  height: 2.5rem;
  left: -8px;
  width: 2.5rem;
  border-radius: 5px;
  content: "";
  background: #f3f4f5;
}
.setting-menu::before {
  @apply -left-2;
}

.setting_actions {
  @apply flex gap-4 items-center text-left;
}

.dropdown_list-contacts__option,
.dropdown_list-contacts-agent__option,
.dropdown_list-contacts-actions__option {
  @apply !px-4 !pt-[0.5625rem] !pb-[0.5625rem] hover:bg-darkmintgreen active:bg-darkmintgreen text-xs leading-4 text-onyx font-medium mb-1;
  font-family: "Inter-Regular";
  font-style: normal;
}

.buyer_location--reapi__control,
.profile_location--reapi__control {
  @apply !h-10 !border-0 !rounded-lg;
}

.profile_location--reapi__value-container {
  @apply !pr-10;
}

.buyer_location--reapi__control {
  @apply !bg-whitesmoke;
}

.manage_agent--add .profile_location--reapi__control {
  @apply !bg-[#F9FAFB];
}

.profile_location--reapi__indicator {
  @apply !hidden;
}

.profile_location--reapi__menu-portal {
  @apply !z-[1112];
}

.homeowner__address-select__menu-portal {
  @apply !z-[250];
}

.homeowner__address-select__control {
  @apply !h-[42px] !bg-whitesmoke !border-[#E6E8EB] !rounded-lg;
}

.homeowner__address-select__value-container {
  @apply !pl-[0.9rem];
}

.homeowner__address-select__placeholder {
  @apply !text-[#9CA3AF];
}

.contacts-address .css-13cymwt-control {
  @apply !h-[2.625rem] rounded-lg cursor-pointer hover:border-steelBlue focus:border-steelBlue hover:shadow-agentsAuthInputs focus:shadow-agentsAuthInputs transition;
}

.ho_properties-address__control {
  @apply !h-[2.625rem] !rounded-[10px] cursor-pointer !border-0;
}
.contacts-address .css-t3ipsp-control {
  @apply !h-[2.625rem] rounded-lg hover:border-steelBlue focus:border-steelBlue hover:shadow-agentsAuthInputs focus:shadow-agentsAuthInputs transition;
}

.manage-btn {
  @apply px-[22px] py-4 font-semibold text-base text-deepkoamaru;
}

.manage-heading {
  @apply font-semibold text-2xl text-deepkoamaru;
}
.manage-list li {
  @apply font-medium text-base text-blackcoral  tracking-widest;
}
.manage-list {
  @apply list-disc list-outside ml-5;
}

.contact_invite li {
  @apply font-medium text-base text-blackcoral;
}
.contact_invite {
  @apply list-disc list-outside ml-5;
}

.contact-form input::placeholder {
  @apply font-medium text-xs text-[#8692A6];
}
.contact-form .css-1jqq78o-placeholder {
  @apply font-medium text-xs text-[#8692A6];
}

.add__contact-address__value-container {
  @apply !px-[0.9rem];
}

.add__contact-address__option,
.add__contact-stream__option,
.add__contact-stream__option--is-selected,
.add__contact-assignAgent__option,
.add__contact-assignAgent__option--is-selected {
  @apply hover:!bg-darkmintgreen active:!bg-darkmintgreen hover:!text-[#353945] !cursor-pointer;
}

.add__contact-address__indicators {
  @apply cursor-pointer;
}

.add__contact-address__indicators .add__contact-address__indicator:nth-child(2),
.add__contact-address__indicators
  .add__contact-address__indicator:nth-child(3) {
  @apply hidden;
}

.add__contact-address__menu-list {
  @apply !max-h-[7rem] h-full;
}

.add__contact-assignAgent__menu-list {
  @apply !max-h-[7rem] h-full;
}
.agent-dropdown .css-d7l1ni-option {
  @apply bg-transparent;
}
.address-dropdown .css-d7l1ni-option {
  @apply bg-transparent;
}

.dropdown_list-contacts__option,
.dropdown_list-contacts-agent__option,
.dropdown_list-contacts-actions__option {
  @apply text-xs font-semibold;
  font-family: "Manrope";
}
.dropdown_list-contacts-agent__menu-portal {
  @apply !z-[9999];
}

/* homeowner reapi dropdown  design */

.search-address__control {
  @apply max-h-[2.5rem];
}
.search-address__value-container {
  @apply max-h-[2.5rem];
}
/* .search-address__indicators {
  @apply !hidden
} */

.homeowner-reapi__menu-list {
  @apply !max-h-[7rem] h-full;
}
.add_property_address .css-13cymwt-control {
  @apply !h-[2.5rem]   cursor-pointer  !rounded-[8px] outline-none bg-whitesmoke  px-4 focus:border-darkturquoise border focus:bg-white;
}
.add_property_address .css-t3ipsp-control {
  @apply !h-[2.5rem];
}

.homeowner-reapi__option,
.homeowner-reapi__option--is-selected {
  @apply hover:!bg-darkmintgreen active:!bg-darkmintgreen hover:!text-onyx !cursor-pointer text-left;
}

.dropdown_list-assign__option,
.dropdown_list-assign__option--is-selected {
  @apply hover:!bg-darkmintgreen active:!bg-darkmintgreen hover:!text-onyx !cursor-pointer text-left;
}

.homeowner-signup-address .css-13cymwt-control {
  @apply !h-[3.25rem] bg-[#EBFDFF] !rounded-[0.625rem] cursor-pointer maxLg:!h-[2.625rem];
}
.homeowner-signup-address .css-t3ipsp-control {
  @apply !h-[3.25rem] !rounded-[0.625rem] maxLg:!h-[2.625rem];
}
.homeowner-reapi__value-container {
  @apply justify-start;
}

.homeowner-reapi__menu {
  @apply !z-[3];
}

.homeowner-reapi__control {
  @apply !border-0 pl-12 h-[3.25rem] !rounded-lg maxLg:pl-8;
}

.dropdown_list-assign__value-container {
  @apply !text-lg;
}

@media (min-width: 767px) and (max-width: 1300px) {
  .manage_agent--button {
    @apply !top-6 !right-6;
  }
}

.message-box {
  padding: 2px 2px;
  font-size: 18px;
  color: #333;
  background-color: #ffffff;
  border: 2px solid transparent;
  border-radius: 30px;
  background-clip: padding-box;
  position: relative;
  z-index: 1;
}
.message-box p {
  font-size: 24px;
  font-weight: 500;
  color: #30345e;
  background-color: #ffffff;
  border: 2px solid transparent;
  border-radius: 51px;
  background-clip: padding-box;
  position: relative;
  z-index: 1;
  padding: 18px 30px;
  @apply maxLg:px-[15px] maxLg:py-[5px] maxLg:text-[1rem];
}

.message-box::before {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  border-radius: 51px;
  background: linear-gradient(to right, #333, #00c6ff);
  z-index: -1;
}

/* Sidebar menu tool tip  */

.sidebar-menu-tooltip .styles-module_dark__xNqje {
  @apply border border-black flex justify-center items-center;
  background: white !important;
  color: black !important;
  left: 115px;
  height: 26px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.setting-logo svg {
  @apply w-6 h-6;
}

.sidebar-menu-tooltip .react-tooltip-arrow {
  @apply border border-black w-[18px] h-[18px];
  border-left-color: transparent;
  border-top-color: transparent;
}

.sidebar-menu-tooltip [class*="react-tooltip__place-right"] > .arrow {
  top: 3px;
  left: -8px;
}

.react-tooltip-arrow {
  top: 3px !important;
  left: -8px !important;
}

.sidebar-menu-tooltip .tooltip {
  padding: 0 !important;
  border-radius: 3px;
  font-size: 90%;
  width: max-content;
  background-color: white;
}
.styles-module_tooltip__mnnfp {
  padding: 0 10px !important;
  padding-left: 14px !important;
}

.sidebar-menu-tooltip [class*="react-tooltip__place-top"] > .arrow {
  transform: rotate(45deg);
}

.sidebar-menu-tooltip [class*="react-tooltip__place-right"] > .arrow {
  transform: rotate(135deg);
}

.sidebar-menu-tooltip [class*="react-tooltip__place-bottom"] > .arrow {
  transform: rotate(225deg);
}

.sidebar-menu-tooltip [class*="react-tooltip__place-left"] > .arrow {
  transform: rotate(315deg);
}

.dropdown_list-assign__control {
  @apply !h-8 !min-h-[2rem] !border-sonicSilver;
}

.dropdown_list-assign__input-container {
  @apply !p-0 !text-sm;
}

.dropdown_list-assign__placeholder,
.dropdown_list-assign__value-container {
  @apply text-xs;
}
.dropdown_list-assign__option {
  @apply !p-2;
}

.dropdown_list-assign__menu {
  @apply !p-0;
}

.dropdown_list-assign__menu-list {
  @apply !py-0  overflow-y-auto !max-h-[7.5rem];
}

.dropdown_list-assign-agent .css-1n6sfyn-MenuList {
  @apply max-h-[110px] w-full mx-auto;
}

.dropdown_list-assign-agent .dropdown_list-assign__control {
  @apply !h-[2.875rem];
}

.dropdown_list-assign__indicator svg {
  @apply w-[0.9rem] h-[0.9rem];
}
.word-break {
  word-break: break-word;
}

/* That style we're using for custimize columns  */
.customize_columns-checkbox input[type="checkbox"] {
  @apply hidden;
}

.customize_columns-checkbox input[type="checkbox"]:checked + label {
  @apply bg-[#C0F0F7] border border-darkturquoise bg-no-repeat bg-center;
  background-image: url("../images/checked-arrow.svg");
}

#custom-checkbox-0 + label {
  @apply !bg-[#F1F1F1] border !border-[#C7C7C7] bg-no-repeat bg-center;
  background-image: url("../images/right-disable.svg") !important;
}

/* .investor_signup-form select, 
.investor_signup-form input {
  @apply text-blackcoral;
} */

.acceptable_checkbox {
  @apply relative;
}
/* .acceptable_checkbox input[type="checkbox"] {
  @apply hidden;
} */

.acceptable_checkbox input[type="checkbox"]:checked + label {
  @apply bg-bubbles border border-darkturquoise bg-no-repeat bg-center;
}

.acceptable_checkbox .acceptable_checkbox-label {
  @apply z-[1] text-sm font-medium text-blackcoral absolute top-2/4 left-2/4 cursor-pointer;
  transform: translate(-48%, -60%);
}

.form_signup input {
  @apply placeholder:text-coolGrey text-sm font-medium;
}

.form_signup select {
  @apply appearance-none text-sm font-medium;
}

.contact_listing tbody tr td {
  @apply align-top;
}

.load-wraper {
  @apply relative h-full w-full z-[1] overflow-hidden rounded-[5px] bg-[#e4e4e4] border border-silversand;
}

.activity {
  @apply absolute left-[-45%] h-full w-[45%] z-[1];
  background-image: linear-gradient(
    to left,
    rgba(251, 251, 251, 0.05),
    rgba(251, 251, 251, 0.3),
    rgba(251, 251, 251, 0.6),
    rgba(251, 251, 251, 0.3),
    rgba(251, 251, 251, 0.05)
  );
  background-image: -moz-linear-gradient(
    to left,
    rgba(251, 251, 251, 0.05),
    rgba(251, 251, 251, 0.3),
    rgba(251, 251, 251, 0.6),
    rgba(251, 251, 251, 0.3),
    rgba(251, 251, 251, 0.05)
  );
  background-image: -webkit-linear-gradient(
    to left,
    rgba(251, 251, 251, 0.05),
    rgba(251, 251, 251, 0.3),
    rgba(251, 251, 251, 0.6),
    rgba(251, 251, 251, 0.3),
    rgba(251, 251, 251, 0.05)
  );
  animation: loading 1s infinite;
}

@keyframes loading {
  0% {
    left: -45%;
  }
  100% {
    left: 100%;
  }
}

.ho_add_property--address__control {
  @apply !pr-0;
}

.react-datepicker__navigation-icon::before {
  top: 13px;
}

.buyer_location--reapi__indicator {
  @apply !hidden;
}

.agent-stats__datepicker .react-datepicker__year-read-view--down-arrow {
  @apply top-[2px];
}

.agent-stats__datepicker .react-datepicker__navigation,
.agent-stats__datepicker .react-datepicker__current-month {
  @apply hidden;
}

.agent-stats__datepicker .react-datepicker__month-dropdown-container {
  @apply mx-1;
}

.agent-stats__datepicker .react-datepicker__year-select,
.agent-stats__datepicker .react-datepicker__month-select {
  @apply !w-fit border border-gray-500;
}

/* filters dropdown design  */

.dropdown_list-filters__control {
  @apply w-44 rounded-[0.375rem] h-[2.625rem];
}

.dropdown_list-filtersRow__control {
  @apply w-full h-[2.625rem] !border-0;
}
.select__currency.dropdown_list-filtersRow__control {
  @apply w-8;
}

.dropdown_list-filtersRow__option,
.dropdown_list-filters__option {
  @apply !px-4 !pt-[9px] !pb-[9px] hover:bg-darkmintgreen active:bg-darkmintgreen  leading-6 text-[#677185] font-medium mb-1 text-sm;
  font-family: "Inter-Regular";
  font-style: normal;
}

.dropdown_list-filters__menu {
  @apply !w-48 !p-0 !z-[10];
}

.dropdown_list-filtersRow__menu {
  @apply !w-full !p-0 !z-[10];
}
.dropdown_list-filtersRow__menu-portal {
  @apply !z-[1112];
}
/* .dropdown_list-amount__control{
  @apply !w-[3.125rem]
} */

.dropdown_list-filtersRow__control {
  @apply !rounded-[0.375rem];
}

.select__currency.dropdown_list-filtersRow__control {
  @apply border-0 border-l !rounded-none bg-[#F2F2F2] !border-[#d1d5db] cursor-pointer;
}

.select__currency.dropdown_list-filtersRow__menu {
  @apply !w-16;
}

.select__currency.dropdown_list-filtersRow__indicators {
  @apply hidden;
}

.property__card--shadow {
  box-shadow: 3px 3px 10px -2px rgba(0, 0.2, 0.2, 0.25);
}

.admin__property-tabs .react-tabs__tab-list {
  @apply flex flex-wrap;
}

.contacts_dropdown__menu {
  @apply !min-w-full;
}

.sent_message:hover .message_delete-icon {
  @apply block;
}

.listing_filter.dropdown_list-contacts-agent__control {
  @apply !h-full;
}

.common_filter.dropdown_list-contacts-agent__control {
  @apply !h-full items-center max-w-[12.375rem] !border-bellflowerBlue border mr-7 hover:!border-bellflowerBlue focus:!border-metallicBlue rounded-md w-full outline-0 p-2  mt-1 hover:!shadow-agentsAuthInputs focus:!shadow-agentsAuthInputs transition text-sm;
}
.userFilter .common_filter.dropdown_list-contacts-agent__control {
  @apply max-w-none;
}
.common_filter.filter_dropdown {
  @apply !h-full items-center !border-bellflowerBlue border mr-7 hover:!border-bellflowerBlue focus:!border-metallicBlue rounded-md w-full outline-0 p-2  mt-1 hover:!shadow-agentsAuthInputs focus:!shadow-agentsAuthInputs transition text-sm;
}
.common_filter.dropdown_list-contacts-agent__dropdown-indicator {
  @apply p-0;
}
.common_filter.dropdown_list-contacts-agent__value-container {
  @apply p-0;
}
/* aithentication  */
.auth_aside--bg {
  background: linear-gradient(
    141.07deg,
    #30345e -24.07%,
    #394e77 27.74%,
    #4282aa 53.43%,
    #338daa 69.39%,
    #2994a9 80.62%,
    #1ac8db 96.3%,
    #c0f0f7 113.95%
  );
}

.profile_conact-tabs .react-tabs__tab--selected:first-child {
  @apply border-l-0;
}

.profile_conact-tabs .react-tabs__tab--selected:last-child {
  @apply border-r-0;
}

.profile_conact-tabs .react-tabs__tab--selected {
  /* @apply rounded-tl-md rounded-tr-md mb-0 mt-[-1px]; */
  @apply mb-0 mt-[-1px];
}

.profile_conact-tabs .react-tabs__tab {
  @apply mb-0 mt-[-1px] text-metallicBlue font-inter-semiBold !px-4 whitespace-nowrap text-ellipsis text-sm;
}

.profile-tab .react-tabs__tab {
  @apply font-inter-bold p-0 mr-8 maxMd:mr-5;
}

.profile-tab .react-tabs__tab--selected {
  @apply text-moonStone font-inter-bold p-0;
}

.tag_design__multi-value {
  @apply pl-2.5 pr-1.5 !bg-aquaMist !rounded;
}
.tag_design__multi-value__remove:hover {
  @apply opacity-85 !bg-transparent;
}

.tag_design__multi-value__label {
  @apply !pl-0 !text-moonStone;
}

.tag_design__multi-value__remove > svg > path {
  @apply fill-moonStone;
}

.investor_selection__control {
  @apply !border-[#000000b3];
}

.archive-contacts .dropdown_list-contacts__menu {
  @apply !w-full;
}

.upload_btn {
  @apply !bg-white hover:!bg-aquaMist w-[1.125rem] h-[1.125rem] rounded-full flex justify-center items-center  border border-black hover:border-metallicBlue absolute -top-[6px] left-[25px];
}

.add_button:hover svg rect {
  @apply fill-metallicBlue stroke-white;
}
.add_button:hover svg path {
  @apply fill-white;
}
.add_tag_button:hover svg rect {
  @apply fill-metallicBlue stroke-white;
}
.add_tag_button:hover svg path {
  @apply fill-white;
}

.download-icon-docs:hover svg path {
  @apply stroke-metallicBlue;
}

.profile_address-dropdown__control {
  @apply !border-0 w-max !cursor-pointer;
}
.profile_address-dropdown__value-container,
.profile_address-dropdown__single-value {
  @apply !whitespace-normal w-[11.8rem];
}

.profile_address-dropdown__menu {
  @apply !w-[12.6rem];
}

.profile_address-dropdown__indicator {
  @apply !p-0;
}

.profile_address-dropdown__indicators {
  @apply !items-start;
}

.multitag .css-t3ipsp-control {
  @apply !h-full;
}

.buying_criteria .add__contact-address__control {
  @apply !border-0;
}

.profile_message-chat .scroolbar_msgbody {
  @apply h-[calc(100vh-35.875rem)] maxMd:h-[calc(100vh-32.875rem)];
}

.profile_message-chat .scroolbar_msgbody ul {
  height: calc(100vh - 41.1875rem);
}

/* that styles we're using for contact profile info rounded tabbing...  */

.contact-tab-design .react-tabs__tab--selected {
  @apply !bg-transparent border-silversand border-b-transparent mb-0 border-t-0 border-b-0 rounded-tr-[0.3125rem] rounded-tl-[0.3125rem];
}
/* .contact-tab-design .react-tabs {
  @apply !border-x border-smokescreen rounded-[0.313rem];
} */

.tab_message_design .react-tabs__tab:first-child {
  @apply rounded-tr-[0.313rem];
}
.tab_message_design .react-tabs__tab:last-child {
  @apply rounded-tl-[0.313rem];
}

.contact-tab-design
  .react-tabs__tab.react-tabs__tab--selected
  + .react-tabs__tab::before {
  @apply absolute -bottom-px right-[calc(100%-0.375rem)] w-3.5 h-3.5 bg-waterfall z-0;
  content: "";
}

.contact-tab-design
  .react-tabs__tab.react-tabs__tab--selected:not(:first-child)::after {
  @apply absolute bottom-0 -left-4 w-4 h-4 bg-waterfall border border-silversand z-[1] rounded-br-md overflow-hidden border-t-transparent border-l-transparent;
  content: "";
}
.contact-tab-design .react-tabs__tab:last-child {
  @apply rounded-tr-md;
}
.contact-tab-design .react-tabs__tab:first-child {
  @apply rounded-tl-md;
}
.contact-tab-design
  .react-tabs__tab.react-tabs__tab--selected:not(:last-child)::before {
  @apply absolute bottom-0 -right-3.5 w-3.5 h-3.5 bg-waterfall border border-silversand z-[1] rounded-bl-md border-t-transparent border-r-transparent;
  content: "";
}

.contact-tab-design
  .react-tabs__tab.react-tabs__tab:focus:not(:first-child):not(
    :last-child
  )::after {
  @apply absolute bottom-0 -left-4 right-[1.4375rem] h-3.5 bg-waterfall  border-t-transparent border-l-transparent;
  content: "";
}
.contact-tab-design .react-tabs__tab.prev::after {
  @apply absolute -bottom-px left-[calc(100%-0.25rem)] w-3.5 h-3.5 border-0 bg-waterfall z-[1];
  content: "";
}

.btn-close {
  @apply absolute top-0 right-4 text-gray-400 mt-[0.95rem] mb-4 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center transition-all outline-0;
}
.property_info li:not(:last-child):after {
  content: "|";
  @apply mx-1;
}

.property_info li {
  @apply text-charcoalBlue whitespace-nowrap overflow-hidden text-ellipsis text-xs font-normal;
}
.tabs-prospector .react-tabs__tab {
  @apply border-none bg-transparent font-bold;
}

.tabs-prospector .react-tabs__tab-list {
  @apply border-none;
}

.tabs-prospector .react-tabs__tab--selected {
  @apply bg-skyBreeze rounded-lg;
}

.tabs-prospector .react-tabs__tab:focus:after {
  @apply bg-transparent;
}

/* Chrome, Safari, Edge, Opera */
.input_number input::-webkit-outer-spin-button,
.input_number input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input_number input[type="number"] {
  -moz-appearance: textfield;
}

.input_number .slider {
  @apply w-full h-1 rounded-[0.3125rem] outline-none opacity-70 bg-steelblue;
  -webkit-appearance: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.input_number .slider::-webkit-slider-thumb {
  @apply w-[1.5625rem] h-[1.5625rem] rounded-[50%] cursor-pointer bg-white border-2 border-steelblue border-solid outline-4 outline outline-icyBlue;
  -webkit-appearance: none;
  appearance: none;
}

.input_number .slider::-moz-range-thumb {
  @apply w-[1.5625rem] h-[1.5625rem] rounded-[50%] cursor-pointer bg-white;
}
.prospector_filter li a {
  @apply min-w-[8.75rem] justify-between w-full;
}
.prospector_filter li a:hover {
  @apply bg-whitesmoke rounded-[0.3125rem];
}
.prospector_history_table table thead th,
tbody td {
  @apply !p-4;
}

.prospector_page .page-disable {
  @apply bg-transparent;
}

@media (max-width: 1200px) {
  .prospector {
    @apply flex-wrap w-full;
  }
  .prospector_image {
    @apply h-60 w-full;
  }
  .comps_image {
    @apply h-[9.3125rem] w-full;
  }
  .prospector_section {
    @apply w-full !h-auto;
  }
}

.badge-primary {
  @apply bg-lightSalome text-fatalFields border-fatalFields border;
}
.badge-secondary {
  @apply bg-tomorrowsCoral text-burtuqaliOrange border-burtuqaliOrange border;
}
.badge-danger {
  @apply bg-coralTrails text-rossoCorsa border-rossoCorsa border;
}

.dropdown_list-agent__control {
  @apply !border-0 w-max !cursor-pointer !items-start;
}
.dropdown_list-agent__value-container,
.dropdown_list-agent__single-value {
  @apply !whitespace-normal w-[5.8rem] !font-medium text-sm !p-0;
}

.dropdown_list-agent__indicator {
  @apply !p-0;
}

.dropdown_list-agent__indicators {
  @apply !items-start;
}

.dropdown_list-agent__input-container {
  @apply !p-0 !text-sm;
}

.dropdown_list-agent__placeholder,
.dropdown_list-agent__value-container {
  @apply text-xs;
}

.dropdown_list-agent__menu {
  @apply !w-40 !min-w-[10rem] !p-0 !z-[10];
}

.dropdown_list-agent__menu-list {
  @apply !py-0 !max-h-32 overflow-y-auto;
}

.dropdown_list-agent__option {
  @apply !px-4 !pt-[0.5625rem] !pb-[0.5625rem] hover:bg-darkmintgreen active:bg-darkmintgreen text-xs leading-4 text-onyx font-medium mb-1;
  font-family: "Inter-Regular";
  font-style: normal;
}

.prospector_pagination .pagination {
  @apply mt-0;
}

.prospector_filter_modal .react-tabs__tab-list {
  @apply !flex-row w-full justify-between;
}

.prospector_filter_modal .react-tabs__tab {
  @apply whitespace-nowrap;
}

.outline-btn {
  @apply border border-moonStone px-[1.0625rem] py-3.5 font-semibold text-sm rounded-md text-moonStone hover:bg-moonStone hover:text-white;
}

.apply-btn {
  @apply px-[1.0625rem] py-3.5 font-semibold text-sm rounded-md !bg-moonStone text-white hover:opacity-80;
}

/* toast message  */

.Toastify__toast--success {
  @apply bg-mintCream border border-verdigris;
}
.Toastify__toast--error {
  @apply bg-seashell border border-melon shadow-toastShadow;
}
.Toastify__toast--success .Toastify__toast-icon svg {
  @apply fill-verdigris stroke-white;
}
.Toastify__toast {
  @apply p-5 gap-2.5 text-sm font-semibold leading-[1.2rem] text-gunmetal rounded-xl;
}
.Toastify__toast-body {
  @apply p-0 m-0 items-center;
}
.Toastify__close-button {
  @apply flex items-center my-auto;
}
.Toastify__toast-icon {
  @apply w-6 h-6;
}
.prospector-slick .slick-slider,
.prospector-slick .slick-slide,
.prospector-slick .slick-slide > div,
.prospector-slick .slick-slide > div > img {
  @apply h-full;
}
.prospector-slick .slick-list {
  @apply rounded-[0.3125rem] maxMd:!p-0;
}
.prospector-slick .slick-track {
  @apply mb-0 pr-0 gap-0;
}
.prospector-slick .slick-slide div img {
  @apply m-0;
}
.prospector-slick .slick-slide {
  @apply w-full;
}
.edit_address > div {
  @apply w-full;
}
@media (max-width: 1111px) {
  .contact-header {
    @apply flex-wrap;
  }
  .contact-search {
    @apply !justify-between;
  }
}
