.auth {
  @apply mx-auto max-w-[22.75rem] w-full py-3 text-center;
}

.banner {
  @apply h-full;
}

.inputGroup {
  @apply relative mb-[1.625rem] flex flex-col items-start;
}

.inputGroup input {
  @apply p-[1.0625rem] px-[3.35rem] rounded-[0.625rem] bg-crystalBlue border border-silversand w-full text-xs;
}

.inputGroup input::placeholder {
  @apply text-charcoal text-sm;
}

.authInputIcon_homehub {
  @apply absolute left-6;
}

.inputGroupOr {
  @apply flex items-center mt-[1.875rem] mb-7;
}

.inputGroupOr span:nth-child(2) {
  @apply mx-auto text-[0.9375rem] font-bold;
}

.inputGroupOr span:not(:nth-child(2)) {
  @apply border-b inline-block w-[6.25rem] border-lavenderHaze;
}

.authInput_homehub {
  @apply flex items-center w-full;
}

.authInput_homehub input {
  @apply focus-visible:outline-none;
}

@screen maxLg {
  .auth {
    @apply bg-white z-[1] p-4 my-auto rounded-xl;
  }

  .banner {
    @apply absolute left-0 right-0 h-full;
  }

  .inputGroup input {
    @apply p-3 pl-10;
  }

  .authInputIcon_homehub {
    @apply left-3;
  }
}

@screen lg {
  .authContent {
    height: calc(100% - 50px);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
